@use "sass:math";

.home-page {
  .page-wrapper {
    position: relative;
  }

  .content-area__wrapper {
    height: calc(100% - 80px);
    padding: 0;
  }

  .header-container {
    position: relative;
    z-index: 101;

    .header {
      background: $dark60;
    }
  }

  .events-sidebar {
    z-index: 102;
  }

  .footer {
    position: absolute;
    bottom: math.div($kbaseRem, 6);
    padding: 0 $kbaseRem;
  }

  @media screen and (min-width: $tablet) {
    .navigation {
      background: $dark60;

      .navigation__item--open {
        background: $darkTwo60;
      }

      &.navigation--narrow
        .navigation__item.navigation__item--has-children
        .navigation__submenu {
        background: $darkTwo60;
      }
    }
  }

  @at-root #{&}__map {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
  }

  @at-root #{&}__map-controls {
    margin: 0;
    position: absolute;
    bottom: $kbaseRem * 2;
    right: $kbaseRem * 3.8;
    z-index: 1;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: flex-end;

    @at-root #{&}__zoom-control {
      display: flex;
      flex-flow: column nowrap;

      .zoom_in {
        margin: 0;
        border-bottom: none;
        border-radius: $borderRadius $borderRadius 0 0;
      }

      .zoom_out {
        margin: 0;
        border-radius: 0 0 $borderRadius $borderRadius;
      }
    }

    @at-root #{&}__map-id-control {
      display: flex;
      flex-flow: row nowrap;
      margin-right: $kbaseRem;

      .roadmap {
        margin: 0;
        border-right: none;
        border-radius: $borderRadius 0 0 $borderRadius;
      }

      .satellite {
        margin: 0;
        border-radius: 0 $borderRadius $borderRadius 0;
      }
    }
  }

  .toggle-container {
    position: absolute;
    z-index: 3;
    right: 1rem;
    top: 16px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    .button {
      background: $dark;
      margin-bottom: 0;
      height: $kbaseRem * 1.45;
      min-width: $kbaseRem * 1.45;

      &:hover,
      &.active {
        background: $blueGradient;
      }
    }
  }

  .home-filters {
    position: relative;
    z-index: 100;
    min-height: 0;
    z-index: 2;

    .filters__active {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      padding: $kbaseRem*0.5 $kbaseRem * 2.8 $kbaseRem*0.5 $kbaseRem;
      background: $dark60;
      border-bottom: $darkBorder;

      @media screen and (min-width: $desktop) {
        flex-flow: row nowrap;
      }

      .label {
        border: $darkBorder;
        height: initial;
        margin-bottom: $kbaseRem*0.25;

        @media screen and (min-width: $tabletPlus) {
          margin: $kbaseRem*0.125 inherit;
        }
      }

      .button {
        margin-bottom: $kbaseRem*0.25;
        min-width: $kbaseRem * 4.5;

        @media screen and (min-width: $tabletPlus) {
          margin: $kbaseRem*0.125 inherit;
        }
      }
    }

    .home-filters-container {
      overflow: hidden;
      background: $dark60;
      will-change: max-height, padding, opacity;
      padding: 0;
      opacity: 0;
      max-height: 0px;
      transition: max-height 0.325s ease-in-out, padding 0.325s ease-in-out,
        opacity 0.325s ease-in-out;

      &.open {
        overflow: visible;
        padding: $kbaseRem;
        display: block;
        opacity: 1;
        max-height: 100vh;
        padding-right: 75px;
        // background: $dark60;
        height: calc(100% - 80px);
        transition: max-height 0.325s ease-in-out, padding 0.325s ease-in-out,
          opacity 0.325s ease-in-out;

        @media screen and (min-width: $tabletPlus) {
          max-height: 200px;
          height: auto;
        }
      }

      @at-root #{&}__top-row {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: $kbaseRem*0.5;

        @media screen and (min-width: $tabletPlus) {
          flex-flow: row nowrap;
        }

        .input {
          width: 100%;

          @media screen and (min-width: $tabletPlus) {
            width: auto;
            margin: 0 $kbaseRem*0.5;
          }
        }

        .filter-toggle {
          &__label {
            padding: 0 $kbaseRem * 0.25;
            font-weight: 500;
          }
        }

        @at-root #{&}--show {
          width: 100%;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: $kbaseRem*0.25;

          @media screen and (min-width: $tabletPlus) {
            width: auto;
            margin-right: $kbaseRem;
            margin-bottom: 0;
          }
        }
      }

      @at-root #{&}__bottom-row {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;

        @media screen and (min-width: $desktop) {
          flex-flow: row nowrap;
        }

        .input__select {
          width: 100%;

          @media screen and (min-width: $tabletPlus) {
            width: 32%;
            margin: 0 $kbaseRem*0.5 0 0;

            &:nth-child(3n) {
              margin-right: 0;
            }
          }

          @media screen and (min-width: $desktop) {
            width: calc(100% / 6);
            margin: 0 $kbaseRem 0 0;

            &:nth-child(3n) {
              margin: 0 $kbaseRem 0 0;
            }

            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
